<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">

            <div class="row">
                <div class="col-12">
                    <h2 class="pink mb-5" style="margin: 22px 0;">
                        Pricing and refunds
                    </h2>
                </div>
                <div class="col-12 col-md-6">
                    <p>
                        We believe in the power of freedom, all payments need to be initiated by the user and, if required
                        by the bank, confirmed with 3-D Secure in line with PSD2 regulations. <br>
                        "Credits" (also called "Tokens") are required to reply or initiate conversations or to send digital
                        presents or requests and are valid for 3 months after purchasing them.
                    </p>

                    <p style="margin-bottom: 22px;">
                        <i>* Prices are including VAT</i><br>
                        To learn more about the Websites refund policy, please check out the <router-link :to="{ name: 'Terms', hash: '#pricing' }">Terms of Service</router-link>.
                    </p>
                </div>

                <div class="col-12 col-md-6">
                    <h3>Subscriptions</h3>

                    <table border="1" style="width: 100%; margin-bottom: 22px;">
                        <thead>
                            <tr>
                                <th style="padding: 5px;">Price</th>
                                <th style="padding: 5px;">Duration</th>
                                <th style="padding: 5px;">Credits</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th colspan="3" style="padding: 5px; text-align: center;">Pound Sterling</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">£1,99</td>
                                <td style="padding: 5px;">3 Days</td>
                                <td style="padding: 5px;">5 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">£49,00</td>
                                <td style="padding: 5px;">30 Days</td>
                                <td style="padding: 5px;">35 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="3" style="padding: 5px; text-align: center;">Euro</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">€1,99</td>
                                <td style="padding: 5px;">3 Days</td>
                                <td style="padding: 5px;">5 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">€49,00</td>
                                <td style="padding: 5px;">30 Days</td>
                                <td style="padding: 5px;">35 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="3" style="padding: 5px; text-align: center;">US Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$1,99</td>
                                <td style="padding: 5px;">3 Days</td>
                                <td style="padding: 5px;">5 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$59,00</td>
                                <td style="padding: 5px;">30 Days</td>
                                <td style="padding: 5px;">35 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="3" style="padding: 5px; text-align: center;">Canadian Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$1,99</td>
                                <td style="padding: 5px;">3 Days</td>
                                <td style="padding: 5px;">5 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$89,00</td>
                                <td style="padding: 5px;">30 Days</td>
                                <td style="padding: 5px;">35 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="3" style="padding: 5px; text-align: center;">Australian Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$1,99</td>
                                <td style="padding: 5px;">3 Days</td>
                                <td style="padding: 5px;">5 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$89,00</td>
                                <td style="padding: 5px;">30 Days</td>
                                <td style="padding: 5px;">35 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="3" style="padding: 5px; text-align: center;">New Zealand Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$1,99</td>
                                <td style="padding: 5px;">3 Days</td>
                                <td style="padding: 5px;">5 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$99,00</td>
                                <td style="padding: 5px;">30 Days</td>
                                <td style="padding: 5px;">35 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="3" style="padding: 5px; text-align: center;">Japanese Yen</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">¥380</td>
                                <td style="padding: 5px;">3 Days</td>
                                <td style="padding: 5px;">5 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">¥7900</td>
                                <td style="padding: 5px;">30 Days</td>
                                <td style="padding: 5px;">35 Tokens</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3>Bundles</h3>

                    <table border="1" style="width: 100%; margin-bottom: 22px;">
                        <thead>
                            <tr>
                                <th style="padding: 5px;">Price</th>
                                <th style="padding: 5px;">Credits</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th colspan="2" style="padding: 5px; text-align: center;">Pound Sterling</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">£10,00</td>
                                <td style="padding: 5px;">10 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">£40,00</td>
                                <td style="padding: 5px;">25 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">£77,50</td>
                                <td style="padding: 5px;">50 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">£150,00</td>
                                <td style="padding: 5px;">100 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="2" style="padding: 5px; text-align: center;">US Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$17,50</td>
                                <td style="padding: 5px;">10 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$40,00</td>
                                <td style="padding: 5px;">25 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$75,00</td>
                                <td style="padding: 5px;">50 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$135,00</td>
                                <td style="padding: 5px;">100 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="2" style="padding: 5px; text-align: center;">Euro</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">€17,50</td>
                                <td style="padding: 5px;">10 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">€40,00</td>
                                <td style="padding: 5px;">25 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">€75,00</td>
                                <td style="padding: 5px;">50 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">€135,00</td>
                                <td style="padding: 5px;">100 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="2" style="padding: 5px; text-align: center;">Australian Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$25,00</td>
                                <td style="padding: 5px;">10 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$60,00</td>
                                <td style="padding: 5px;">25 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$115,00</td>
                                <td style="padding: 5px;">50 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$220,00</td>
                                <td style="padding: 5px;">100 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="2" style="padding: 5px; text-align: center;">Canadian Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$25,90</td>
                                <td style="padding: 5px;">10 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$59,99</td>
                                <td style="padding: 5px;">25 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$110,00</td>
                                <td style="padding: 5px;">50 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$199,99</td>
                                <td style="padding: 5px;">100 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="2" style="padding: 5px; text-align: center;">New Zealand Dollar</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$27,00</td>
                                <td style="padding: 5px;">10 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$65,00</td>
                                <td style="padding: 5px;">25 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$130,00</td>
                                <td style="padding: 5px;">50 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">$240,00</td>
                                <td style="padding: 5px;">100 Tokens</td>
                            </tr>
                            <tr>
                                <th colspan="2" style="padding: 5px; text-align: center;">Japanese Yen</th>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">¥2700</td>
                                <td style="padding: 5px;">10 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">¥6200</td>
                                <td style="padding: 5px;">25 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">¥11500</td>
                                <td style="padding: 5px;">50 Tokens</td>
                            </tr>
                            <tr>
                                <td style="padding: 5px;">¥21000</td>
                                <td style="padding: 5px;">100 Tokens</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Pricing',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
};
</script>
